/**
 * Composable function to utilize the IDP-related kauth flow.
 * @param {ComputedRef<boolean>} idpIsEnabled - If true, automatically handle IDP in the onMounted lifecycle hook.
 * @returns {*} {IdentityProviderComposable}
 */
export default function useIdentityProvider(idpIsEnabled: ComputedRef<boolean>) {
  const { info } = storeToRefs(usePortalStore())
  const route = useRoute('login-login_path')

  const isIdpLogin = useState<boolean>(() => false)
  // Initialize as true if the route query contains the OIDC code and state
  const idpIsLoading = useState<boolean>(() => !!route.query.code && !!route.query.state)
  const isRedirecting = useState<boolean>(() => false)

  // 'code' and 'state' are the only currently required oidc-callback query parameters
  const code = useState<string>(() => '')
  const state = useState<string>(() => '')

  /**
   * Returns true if developer is on route `/login/sso` and `logout=true` is NOT in the query string.
   * @returns {Promise<boolean>}
   */
  const shouldTriggerIdpLogin = async (): Promise<boolean> => {
    // If called before window exists, exit
    if (typeof window === 'undefined') {
      console.error("'shouldTriggerIdpLogin' should only be called in the 'onMounted' lifecycle hook")
      return false
    }

    // Check for /login/sso path
    isIdpLogin.value = route.path.startsWith('/login') && String(route.params.login_path || '') === 'sso'

    // If developer is on /login/{string} that is not equal to 'sso' redirect back to the normal login page
    if (!isIdpLogin.value && route.path.startsWith('/login') && route.params.login_path && String(route.params.login_path || '') !== 'sso') {
      await navigateTo({ path: '/login' })
      return false
    }

    // If not IdP login, or if logout in URL params (user came from logout), exit
    if (!isIdpLogin.value || !!String(route.query.logout || '')) {
      return false
    }

    // Trigger loading
    idpIsLoading.value = true

    return true
  }

  /**
   * Redirect the user to the kauth/authenticate/{org-id} endpoint, and provide a returnTo path.
   * @param {string} [returnTo] - The full URL (including https://) where to return the user to with the code and state.
   * @returns {Promise<void>}
   */
  const redirectToIdp = async (): Promise<void> => {
    // If called before window exists, exit
    if (typeof window === 'undefined') {
      console.error(
        "'redirectToIdp' should only be called client-side",
      )
      return
    }

    idpIsLoading.value = true

    // Create a URL from returnTo and encode for query string. Fail if not a valid URL.
    let returnToParam

    try {
      // Create new URL from returnTo, wrapped in try/catch to construct the URL object
      const returnToUrl = new URL(window.location.origin + '/login')

      // Encode for query string param
      returnToParam = `returnTo=${encodeURIComponent(returnToUrl.href)}`
    } catch (_) {
      idpIsLoading.value = false
      // Console warning references the element prop name instead of local variable

      console.error("'idpLoginReturnTo' must be a valid URL")
      return
    }

    // Prevent additional redirects while processing
    isRedirecting.value = true

    // Create an array to hold the URL params
    const urlParams = []

    // Always add the returnTo param
    urlParams.push(returnToParam)

    // Combine URL params, skipping any that are empty
    const redirectParams = '?' + urlParams.filter(Boolean).join('&')

    if (!info.value?.portal_id) {
      // Reset loading state
      idpIsLoading.value = false

      // Exit early
      console.error("'portal_id' is required")
      return
    }

    await navigateTo(`${window.location.origin}/api/v2/developer/authenticate/sso${redirectParams}`, {
      external: true,
    })
  }

  /**
   * Returns if URL contains code and state parameters to use for IDP authentication.
   * @returns {boolean}
   */
  const shouldTriggerIdpAuthentication = async (): Promise<boolean> => {
    // If called before window exists, exit
    if (typeof window === 'undefined') {
      console.error(
        "'shouldTriggerIdpAuthentication' should only be called in the 'onMounted' lifecycle hook",
      )
      return false
    }

    // We need to ensure code and state are set (required)
    code.value = String(route.query.code || '')
    state.value = String(route.query.state || '')

    // Check for required url params
    if (!code.value || !state.value) {
      // If at least one is present, log an error
      if (code.value || state.value) {
        console.error(
          "'shouldTriggerIdpAuthentication' could not extract the required OIDC query parameters",
        )
      }
      return false
    }

    // Trigger loading
    idpIsLoading.value = true

    return true
  }

  const authenticateWithIdp = async (): Promise<void> => {
    // Ensure required parameters are set
    if (!code.value || !state.value) {
      idpIsLoading.value = false
      return
    }

    // Prevent additional redirects while processing
    isRedirecting.value = true

    // Make a request to the internal endpoint (that proxies the real endpoint) to modify the `set-cookie` headers
    // Send along all provided query parameters
    const oidcRedirect: string = await $fetch(`/api/session/oidc-callback?${new URLSearchParams(window.location.search)?.toString()}`, {
      method: 'GET',
    })

    // Redirect the user to the oidcRedirect path
    await navigateTo(oidcRedirect, {
      external: true,
    })
  }

  onMounted(async () => {
    // If IDP is not enabled on login component, do not trigger listeners in onMounted function
    if (!idpIsEnabled.value) {
      return
    }

    // Check for IDP login
    if (await shouldTriggerIdpLogin()) {
      await redirectToIdp()
      return
    }

    if (await shouldTriggerIdpAuthentication()) {
      await authenticateWithIdp()
    }
  })

  return {
    idpIsLoading,
    redirectToIdp,
  }
}
